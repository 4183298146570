import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"
import Prodopt from "../../../components/Prodopt"
import Prodfeat from "../../../components/Prodfeat"



const FDP = () => (
    <Layout>
        <Container>
            <div className="column">
                <br /><br />
                <div class="text-center">
                    {/* Картинка */}
                    <StaticImage
                        src="img/evodisc.png"
                        width={790}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="EVODISC-TK"
                    />
                </div>
                {/* Заголовок */}
                <h1 class="text-center py-3">
                    ДИСКОВАЯ БОРОНА EVODISC-TK
                </h1>
                {/* Текст */}
                <p class="blockquote card px-4 mx-5 py-3">
                ПРИЦЕПНАЯ БОРОНА С ГИДРАВЛИЧЕСКОЙ РЕГУЛИРОВКОЙ ГЛУБИНЫ. СКЛАДНАЯ РАМА.
                </p>

                <h2 class="text-center py-3 text-black-50">
                    ПРЕИМУЩЕСТВА ПРОДУКТА
                </h2>

                <div class="row justify-content-center text-center px-5">
                    <Prodfeat text="Ролик с одинарной клеткой" />
                    <Prodfeat text="Смазка концентраторов" />
                    <Prodfeat text="Механическая регулировка угла" />
                    <Prodfeat text="Размер шин 400/60-15,5" />
                    <Prodfeat text="Полуприцепное дышло" />


                </div>

                {/* Преимущества */}
                <h2 class="text-center pt-3 text-black-50">
                    ОПЦИИ
                </h2>
                <div class="row justify-content-center text-center px-5">
                    <Prodfeat text="Двойной игольчатый каток" />
                    <Prodfeat text="Игольчатый каток" />
                    <Prodfeat text="Одинарный спиральный каток" />
                    <Prodfeat text="Двойной спиральный каток" />
                </div>

                <h2 class="text-center py-3 text-black-50">
                    ТЕХНИЧЕСКИЕ СПЕЦИФИКАЦИИ
                </h2>
                {/* Таблица */}

                <div class="table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                        <thead class="background-dark-gray-ozdo"><tr><th>МОДЕЛЬ</th>
                            <th>РАБОЧАЯ ШИРИНА (см)</th>
                            <th>ТРАНСПОРТНАЯ ШИРИНА (см)</th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>EVODISC-TK400</td>
                                <td>400</td>
                                <td>300</td>
                            </tr>
                            <tr>
                                <td>EVODISC-TK500</td>
                                <td>500</td>
                                <td>300</td>
                            </tr>
                            <tr>
                                <td>EVODISC-TK600</td>
                                <td>600</td>
                                <td>300</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </Container>
    </Layout>
);

export default FDP;